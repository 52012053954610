.tournamentCardPrizeBgImage {
  image-rendering: pixelated;
  flex-shrink: 0;
  background-size: 100% 100%;
  background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20viewBox%3D%220%200%20170%2070%22%3E%3Cg%3E%3Cdefs%3E%3ClinearGradient%20id%3D%22idss668606921_1g1882718634%22%20gradientTransform%3D%22rotate(138%2C%200.5%2C%200.5)%22%3E%3Cstop%20offset%3D%220%22%20stop-color%3D%22%2326b3ff%22%20stop-opacity%3D%221%22%3E%3C%2Fstop%3E%3Cstop%20offset%3D%221%22%20stop-color%3D%22%23a137ff%22%20stop-opacity%3D%221%22%3E%3C%2Fstop%3E%3C%2FlinearGradient%3E%3C%2Fdefs%3E%3Cpath%20d%3D%22M%2011.5%200%20L%20170%200%20L%20170%2070%20L%200%2070%20Z%22%20fill%3D%22url(%23idss668606921_1g1882718634)%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  height: 70px;
  left: calc(52.5% - 85px);
  position: absolute;
  top: calc(49% - 35px);
  width: 170px;
}
