.footer .upper {
  background: linear-gradient(
    56deg,
    rgb(194, 41, 255) 0%,
    rgb(0, 200, 240) 100%
  );
}

.footer .lower {
  background: linear-gradient(
    56deg,
    rgb(99, 15, 149) 0%,
    rgb(3, 114, 165) 100%
  );
}
