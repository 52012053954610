.price-gray-bg {
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
  border-radius: 40px;
  box-shadow: rgb(0 0 0 / 5%) 0px 8px 16px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}

.price-colored-bg {
  background: linear-gradient(
    340deg,
    rgb(0, 157, 230) 0%,
    rgb(171, 33, 254) 100%
  );
  opacity: 1;
  border-radius: 40px;
  box-shadow: rgb(0 0 0 / 5%) 0px 8px 16px 0px, rgb(0 0 0 / 10%) 0px 2px 4px 0px;
}
