.main-spot {
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  flex: 0 0 auto;
  overflow: visible;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.main-spot1 {
  opacity: 0.5;
}

.main-spot2 {
  opacity: 0.3;
}

.main-spot3 {
  opacity: 0.4;
}

.img-spot-main {
  pointer-events: none;
  user-select: none;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  object-position: center;
  object-fit: cover;
  image-rendering: auto;
}
